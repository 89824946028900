<template>
  <div>
    <span>
      You have been {{ action }} as {{ contributor.role }} {{ action === 'removed' ? 'from' : 'to' }}
    </span>
    <span class="text-primary">{{ board.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    notification: { type: Object, required: true },
  },

  computed: {
    contributor () {
      return this.notification.data.contributor
    },

    board () {
      return this.contributor.board
    },

    action () {
      return this.notification.data.action
    },
  },
}
</script>
