<template>
  <div>
    <!-- new comment created -->
    <div v-if="isBoardCommented">
      <span class="text-primary">
        {{ user.username }}
      </span>
      <span>commented on your board</span>
      <span class="text-primary">{{ activity.actable.name }}</span>
    </div>

    <!-- new post created -->
    <div v-if="isBoardPostCreated">
      <span class="text-primary">
        {{ user.username }}
      </span>
      <span>created a new post on your board</span>
      <span class="text-primary">{{ activity.actable.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: { type: Object, required: true },
  },

  computed: {
    activity () {
      return this.notification.data.activity
    },

    user () {
      return this.notification.data.user
    },

    isBoardCommented () {
      return this.activity.addable_type === 'comments' && this.activity.actable_type === 'boards'
    },

    isBoardPostCreated () {
      return this.activity.addable_type === 'posts' && this.activity.actable_type === 'boards'
    },
  },
}
</script>
