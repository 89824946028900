export function isCommentReplied (notification) {
  return notification.type === 'App\\Notifications\\CommentReplyNotification'
}

export function isContribution (notification) {
  return notification.type === 'App\\Notifications\\ContributorNotification'
}

export function isActivity (notification) {
  return notification.type === 'App\\Notifications\\ActivityNotification'
}
