<template>
  <NuxtLink
    :to="url"
    class="cursor-pointer rounded bg-gray-100 p-3 hover:bg-gray-200 hover:text-current"
    :class="unread ? 'font-medium' : 'font-light'"
    @click="handleClick"
  >
    <div>
      <!-- Comment replied notification -->
      <CommentReplied v-if="isCommentReplied(notification)" :notification="notification" />
      <!-- Contributor notification -->
      <Contribution v-if="isContribution(notification)" :notification="notification" />
      <!-- Activity notification -->
      <Activity v-if="isActivity(notification)" :notification="notification" />
    </div>
    <time class="text-xs font-light">
      {{ $f.fromNow(notification.created_at) }} ago
    </time>
  </NuxtLink>
</template>

<script>
import CommentReplied from '~/components/navbar/notifications/CommentReplied'
import Contribution from '~/components/navbar/notifications/Contribution'
import { isActivity, isCommentReplied, isContribution } from '~/supports/notification'
import Activity from '~/components/navbar/notifications/Activity'
import { useApi } from '~/composables/useApi'

export default {
  components: { Activity, CommentReplied, Contribution },

  props: {
    notification: { type: Object, required: true },
  },

  emits: ['onRead'],

  data: () => ({
    unread: false,
  }),

  computed: {
    url () {
      if (isCommentReplied(this.notification)) {
        return `/${this.commentable.user.username}/${this.commentable.slug}#comment-${this.notification.data.comment.id}`
      } else if (isContribution(this.notification)) {
        return `/${this.contributor.board.user.username}/${this.contributor.board.slug}`
      } else if (isActivity(this.notification)) {
        // this subject now can activity for comment and post
        const subject = {
          comments: 'comment',
          posts: 'post',
        }[this.activity.addable_type] || this.activity.addable_type // not sure about this

        return `/${this.activity.actable.user.username}/${this.activity.actable.slug}#${subject}-${this.activity.addable.id}`
      }

      return '/' // return to home page xD
    },

    activity () {
      return this.notification.data.activity
    },

    commentable () {
      return this.notification.data.commentable
    },

    contributor () {
      return this.notification.data.contributor
    },
  },

  mounted () {
    this.unread = this.notification.read_at === null
  },

  methods: {
    handleClick () {
      // mark as read
      this.markAsRead()
    },

    async markAsRead () {
      await useApi(`/api/notifications/${this.notification.id}/read`, { method: 'post' })
      this.$emit('onRead', this.notification)
    },

    isContribution,
    isCommentReplied,
    isActivity,
  },
}
</script>
