<template>
  <div>
    <span>Replied to your comment</span>
    <span class="text-primary">
      '{{ $f.truncate(notification.data.comment.comment, 50) }}'
    </span>
  </div>
</template>

<script>
export default {
  props: {
    notification: { type: Object, required: true },
  },
}
</script>
